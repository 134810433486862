// const BASE_URL = "https://staging-api.cqrvault.org/";
// const BASE_URL = "http://localhost:3050/";
const BASE_URL = "https://api.cqrvault.org/";
//
let FILE_TYPE = {
  GPA_LEVEL: "gpaLevel",
  PROFILE_PICTURE: "profilePicture",
};
export {
  BASE_URL,
  FILE_TYPE,
  // VERIFY_lOGIN_url
};
export const NFT_PURCHASE_METHODS = {
  CRYPTO: "crypto",
  FIAT: "fiat",
  TOKEN: "token",
};

export const DIRECT_WIRE_TYPE = {
  TOKEN_PURCHASE: "tokenPurchase",
  NFT_PURCHASE: "nftPurchase",
  WALLET_ACTIVATION: "walletActivation",
  SUBSCRIPTION: "subscription",
};

export const UserTypes = {
  CQR_USER: "standard",
  SDIRA: "sdira",
  IRA: "ira",
};
