import React, { useEffect, useRef, useState } from "react";
import style from "./signup.module.scss";
import Logo from "../../../Assets/image/logo.svg";
import Password from "../../../Assets/image/passwordeye.svg";
import PasswordShow from "../../../Assets/image/password.svg";
import Modal from "../../_Common/Modal/Modal";
import useSignup from "./useSignup";
import "react-phone-number-input/style.css";
import { LoadingButton, WaveClone } from "../../_Common/loader/Loader";
import ReCAPTCHA from "react-google-recaptcha";

const Signup = ({ signuphandle, visible, onClose }) => {
  const [type, setType] = useState("password");
  const [typeTwo, setTypeTwo] = useState("password");
  const {
    setAgree,
    handleInputChange,
    handleSubmit,
    notAgreed,
    passwordValidation,
    confirmValidation,
    emailValidation,
    ButtonLoading,
    googlerecaptcha,
    show,
    humanValidation,
    nameValidation,
  } = useSignup(onClose, signuphandle);
  const handleEvent = (e) => {
    e.preventDefault();
  };
  const handleTypeChange = (name) => {
    if (name === "text") {
      setType("password");
    } else {
      setType("text");
    }
  };
  const handleTypeTwoChange = (name) => {
    if (name === "text") {
      setTypeTwo("password");
    } else {
      setTypeTwo("text");
    }
  };

  let emailActive = useRef(null);
  useEffect(() => {
    emailActive?.current?.focus();
  }, [visible]);

  const url2 =
    // "https://res.cloudinary.com/lazydev/image/upload/v1643880680/Terms_Conditions.pdf";
    "https://transfer.app.box.com/s/itb74fi26xdks6bic9ebky3ym2txqywe";

  return (
    <>
      <Modal
        signuphandle={signuphandle}
        visible={visible}
        onClose={onClose}
        id="gfdg543g5gv"
      >
        <div className={style.container}>
          <div className={style.wrapper}>
            <div className={style.logo}>
              <img src={Logo} alt="logo" />
            </div>
            <div>
              <form className={style.formbody} onSubmit={(e) => handleEvent(e)}>
                <div className={style.inputfield}>
                  <div className={style.field}>
                    <input
                      ref={emailActive}
                      onChange={(e) => handleInputChange(e)}
                      type="text"
                      name="name"
                      placeholder="Full Name"
                      required="required"
                    />
                  </div>
                </div>
                {nameValidation ? (
                  <p style={{ color: "#E5516B", textAlign: "left" }}>
                    {nameValidation}
                  </p>
                ) : (
                  ""
                )}
                <div className={style.inputfield}>
                  <div className={style.field}>
                    <input
                      onChange={(e) => handleInputChange(e)}
                      type="email"
                      name="email"
                      placeholder="Email"
                      required="required"
                    />
                  </div>
                </div>
                {emailValidation ? (
                  <p style={{ color: "#E5516B", textAlign: "left" }}>
                    {emailValidation}
                  </p>
                ) : (
                  ""
                )}

                <div className={style.passwordfield}>
                  <div className={style.field}>
                    <input
                      onChange={(e) => handleInputChange(e)}
                      type={type}
                      name="password"
                      placeholder="Password"
                      required="required"
                    />
                    <img
                      onClick={() => handleTypeChange(type)}
                      src={type === "text" ? PasswordShow : Password}
                      alt="Password"
                    />
                  </div>
                  {passwordValidation ? (
                    <p style={{ color: "#E5516B", textAlign: "left" }}>
                      {passwordValidation}
                    </p>
                  ) : (
                    ""
                  )}
                </div>
                <div className={style.passwordfield}>
                  <div className={style.field}>
                    <input
                      onChange={(e) => handleInputChange(e)}
                      type={typeTwo}
                      name="resetpassword"
                      placeholder="Confirm Password"
                      required="required"
                    />
                    <img
                      onClick={() => handleTypeTwoChange(typeTwo)}
                      src={typeTwo === "text" ? PasswordShow : Password}
                      alt="Password"
                    />
                  </div>
                  {confirmValidation ? (
                    <p style={{ color: "#E5516B", textAlign: "left" }}>
                      {confirmValidation}
                    </p>
                  ) : (
                    ""
                  )}
                </div>
                <div className={style.inputfield} style={{ padding: "7px 0" }}>
                  <input
                    type="checkbox"
                    onClick={() =>
                      setAgree((prev) => ({
                        ...prev,
                        isAgree: !prev.isAgree,
                        notAgreed: false,
                      }))
                    }
                  />
                  &nbsp;
                  <span>
                    Agree to&nbsp;
                    <a
                      href={url2}
                      rel="noreferrer"
                      target="_blank"
                      style={{ textDecoration: "underline" }}
                    >
                      Terms
                    </a>
                    &nbsp;and&nbsp;
                    <a
                      href={url2}
                      rel="noreferrer"
                      target="_blank"
                      style={{ textDecoration: "underline" }}
                    >
                      Conditions
                    </a>
                  </span>
                </div>
                {notAgreed && (
                  <small style={{ color: "#E5516B", textAlign: "left" }}>
                    You need to agree to our terms and conditions.
                  </small>
                )}
                <div>
                  <ReCAPTCHA
                    // staging link k lye
                    // sitekey="6LeponkeAAAAAKLOdnUHFAPVSQR0DY1CpQbhbkp3"
                    //****** */
                    //live link k lye
                    sitekey="6LeOh_IgAAAAAPKal54_jO1uyTcyN-2kTdZRNmRR"
                    //****** */
                    onChange={(e) => googlerecaptcha(e)}
                  />
                </div>

                {show ? (
                  <p style={{ color: "#E5516B" }}>
                    Please verify you are human
                  </p>
                ) : (
                  ""
                )}
                <div className={style.butttonfield}>
                  {ButtonLoading ? (
                    <button className={style.btn}>
                      <WaveClone />
                    </button>
                  ) : (
                    <button
                      onClick={() => handleSubmit()}
                      className={style.btn}
                    >
                      Sign Up
                    </button>
                  )}
                </div>
                <div className={style.inputfield}>
                  <p onClick={() => signuphandle()}>
                    Already Have an Account?&nbsp;
                    <div>
                      <span
                        onClick={() => {
                          signuphandle();
                        }}
                      >
                        {" "}
                        Sign In
                      </span>
                    </div>
                  </p>
                </div>
              </form>
            </div>
          </div>
        </div>
      </Modal>
    </>
  );
};
export default Signup;
