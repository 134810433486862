import React, { useEffect, useRef, useState } from "react";
import style from "./sigin.module.scss";
import Logo from "../../../Assets/image/logo.svg";
import Email from "../../../Assets/image/Email.svg";
import Password from "../../../Assets/image/password.svg";
import PasswordHide from "../../../Assets/image/passwordeye.svg";
import Modal from "../../_Common/Modal/Modal";
import useLogin from "./useLogin";
import ReCAPTCHA from "react-google-recaptcha";
import { LoadingButton, WaveClone } from "../../_Common/loader/Loader";
import ChangePasswordScreen from "../../DashBoardLayout/Dashboard/changePassword/ChangePasswordScreen";

const Signin = ({
  forgetHandle,
  signinhandle,
  visible,
  onClose,
  OpenAccount,
}) => {
  const {
    handleInputChange,
    handleSubmit,
    googlerecaptcha,
    show,
    passwordValidation,
    emailValidation,
    ButtonLoading,
    changePasswordShow,
    closeChangePasswordModal,
  } = useLogin(onClose, OpenAccount);

  let emailActive = useRef(null);
  useEffect(() => {
    emailActive?.current?.focus();
  }, [visible]);

  const [type, setType] = useState("password");
  const handleTypeChange = (name) => {
    if (name === "text") {
      setType("password");
    } else {
      setType("text");
    }
  };
  //
  return (
    <>
      <ChangePasswordScreen
        showChangePassword={changePasswordShow}
        closeChangePasswordModal={closeChangePasswordModal}
      />
      <Modal visible={visible} onClose={onClose}>
        <div className={style.container}>
          <div className={style.wrapper}>
            <div className={style.logo}>
              <img src={Logo} alt="logo" />
            </div>
            <form onSubmit={() => handleSubmit()}>
              <div className={style.formbody}>
                <div className={style.inputfield}>
                  <div className={style.field}>
                    <input
                      ref={emailActive}
                      onChange={(e) => handleInputChange(e)}
                      name="email"
                      type="email"
                      placeholder="Email"
                      required="required"
                    />
                    <img src={Email} alt="Email" />
                  </div>
                </div>
                {emailValidation ? (
                  <p style={{ color: "#E5516B" }}>{emailValidation}</p>
                ) : (
                  ""
                )}
                <div className={style.inputfield}>
                  <div className={style.field}>
                    <input
                      onChange={(e) => handleInputChange(e)}
                      name="password"
                      type={type}
                      placeholder="Password"
                      required="required"
                    />
                    <img
                      onClick={() => handleTypeChange(type)}
                      src={type === "text" ? Password : PasswordHide}
                      alt="Password"
                    />
                  </div>
                  {passwordValidation ? (
                    <p style={{ color: "#E5516B" }}>{passwordValidation}</p>
                  ) : (
                    ""
                  )}

                  <div className={style.plink}>
                    <label onClick={() => forgetHandle()}>
                      Forgot Password ?
                    </label>
                  </div>
                </div>

                {show ? (
                  <p style={{ color: "#E5516B" }}>
                    Please verify you are human
                  </p>
                ) : (
                  ""
                )}
                <div className={style.inputfield}>
                  <button
                    type="submit"
                    onClick={handleSubmit}
                    className={style.btn}
                  >
                    {ButtonLoading ? <WaveClone /> : "Sign In"}
                  </button>
                </div>
                <div className={style.inputfield}>
                  <p>
                    Don’t have an account?&nbsp;
                    <span onClick={() => signinhandle()} id="tempAccount">
                      Create Account
                    </span>
                  </p>
                </div>
              </div>
            </form>
          </div>
        </div>
      </Modal>
    </>
  );
};

export default Signin;
/////uytgfds
