import React, { useEffect, useState } from "react";
import DashboardChart from "../../../../_Common/DashboardChart/DashboardChart";
import Table from "../../../../_Common/DashboardTable/Table";
import Button from "../../../../_Common/DashboardButton/Button";
import styles from "./CryptoCurrency.module.scss";
import Modal from "../../../../_Common/Modal/Modal";
import Popup from "../../../../_Common/DashboardComponents/popup/popup";
import { useHistory, useLocation } from "react-router-dom";
import useCryptoCurrency from "./useCryptoCurrency";
import { useDispatch, useSelector } from "react-redux";
import refrsh from "../../../../../Assets/icons/refrsh.svg";
import {
  getCurrencyDetail,
  currencyCleanUp,
  currencyLogoutCleanUp,
  graphData,
  networkData,
} from "../../../../../redux/action/currencyDetailAction";
import Alert from "../../../../_Common/DashboardComponents/alert/alert";
import {
  getSetting,
  checkWalletsActive,
  AseetsForWalletActivation,
  UpdateWalletBalance,
} from "../../../../../redux/action/currencyDetailAction";
import { useAlert } from "react-alert";
import { CHAT_SOCKET_TYPES } from "../../../../../utils/ChatConstants";
import {
  subscriptionCleanUp,
  subscriptionStatus,
} from "../../../../../redux/action/subscriptionAction";
import { useContext } from "react";
import { SocketContext } from "../../../../../Context/Socket";
// import PrivateChat from "../../../../_Common/Chat/PrivateChat";
import $ from "jquery";
import { HTTP_CLIENT } from "../../../../../utils/services";
import { BASE_URL } from "../../../../../utils/AppConstants";
import {
  KYCCleanUp,
  userCleanUp,
  userInfo,
} from "../../../../../redux/action/userAction";
import { useMemo } from "react";
import { swapLogoutCleanUp } from "../../../../../redux/action/sawapAction";
import { remittanceCleanUp } from "../../../../../redux/action/remittanceAction";
import { getNetwork } from "../../../../../services/paymentServices";
import HistoryTable from "../CQR Vest/My Assets/MyAssetsTable/AssetsTable";
function CryptoCurrency() {
  useEffect(() => {
    $('img[alt="Chat-Icon"]').show();
  }, []);
  const history = useHistory();
  const location = useLocation();

  const dispatch = useDispatch();
  const alert = useAlert();
  const [popupvisible, setpopupvisible] = useState(false);
  const [number, setNumber] = useState(1);
  const [isHideBalance, setHideBalance] = useState(false);
  const [totalBalance, setTotalBalance] = useState(0);
  const [totalAsset, setTotalAsset] = useState(0);
  const [liquidityTokens, setLiquidityTokens] = useState(0);
  const [totalSdiraTokens, setTotalSdiraTokens] = useState(0);
  const [oppertunity, setOppertunity] = useState("");
  const [activeButton, setActiveButton] = useState(
    history?.location?.state?.activeState != null
      ? history?.location?.state?.activeState
      : 2
  );
  const [active, setActive] = useState(false);
  const [isActivate, setIsActivate] = useState(true);

  const currencySelector = useSelector((state) => state?.currency);
  const nativeToken = useSelector(
    (state) => state?.currency?.currencyInfo?.nativeWallets
  );
  let liquidityToken = nativeToken?.filter((a) => a?.type === "liquidity");
  let assetToken = nativeToken?.filter((a) => a?.type === "asset");
  let sdiraTokens = nativeToken?.filter((a) => a?.type === "sdira");
  const isSidra = useSelector((state) => state?.user?.userInfo?.sdira);
  const block = useSelector((state) => state?.user?.userInfo?.isBlocked);
  const userEmail = useSelector((state) => state?.user?.userInfo?.email);
  const tokenList = useSelector(
    (state) => state?.currency?.currencyInfo?.nftWallets
  );

  const togglepopup = (e, num) => {
    e.preventDefault();
    setNumber(num);
    setpopupvisible((preview) => !preview);
  };
  //----------------Get USD to show in PIE Charts---------//
  const arrToDisplay = useMemo(() => {
    return assetToken?.map((currency) => {
      let a = currency?.balance * currency?.price;
      currency.USD = a;
      return currency;
    });
  }, [assetToken]);
  const liquidityDisplay = useMemo(() => {
    return liquidityToken?.map((currency) => {
      let a = currency?.balance * currency?.price;
      currency.USD = a;
      return currency;
    });
  }, [liquidityToken]);
  const sdiraTokenDisplay = useMemo(() => {
    return sdiraTokens?.map((currency) => {
      let a = currency?.balance * currency?.price;
      currency.USD = a;
      return currency;
    });
  }, [sdiraTokens]);
  // useEffect(() => {
  //   dispatch(getCurrencyDetail());
  // }, [currencySelector?.walletUpdateBalance]);

  //----------Get Wallet check from reducer-------------//
  useEffect(() => {
    setIsActivate(currencySelector?.currencyInfo?.user?.isWalletActivated);
  }, [currencySelector?.currencyInfo?.user?.isWalletActivated]);

  //----------------Sum Oppertunity Balance---------//

  //----------------Sum coin balance---------//
  useEffect(() => {
    let sum = tokenList?.reduce(function (prev, current) {
      return prev + current?.balance;
    }, 0);
    setOppertunity(Number(sum));
  }, [tokenList]);

  useEffect(() => {
    const sum = arrToDisplay?.reduce(
      (partialSum, a) => partialSum + parseFloat(a?.USD),
      0
    );
    setTotalAsset(Number(sum?.toFixed(3)));
  }, [arrToDisplay]);

  useEffect(() => {
    const sum = liquidityDisplay?.reduce(
      (partialSum, a) => partialSum + parseFloat(a?.USD),
      0
    );
    setLiquidityTokens(Number(sum?.toFixed(2)));
  }, [liquidityToken]);
  useEffect(() => {
    const sum = sdiraTokenDisplay?.reduce(
      (partialSum, a) => partialSum + parseFloat(a?.USD),
      0
    );
    setTotalSdiraTokens(Number(sum?.toFixed(2)));
  }, [sdiraTokens]);
  useEffect(() => {
    const sum = currencySelector?.currencyInfo?.wallets?.reduce(
      (partialSum, a) => partialSum + a?.USD,
      0
    );
    setTotalBalance(Number(sum?.toFixed(2)));
  }, [sdiraTokens]);
  //-----------------------------------------------------------//

  useEffect(() => {
    dispatch(
      checkWalletsActive(
        currencySelector?.currencyInfo?.user?.isWalletActivated
      )
    );
    dispatch(AseetsForWalletActivation());
  }, [isActivate]);
  const getInfo = async () => {
    await HTTP_CLIENT.get("user/info");
  };

  const getNetowrkData = async () => {
    try {
      const response = await getNetwork();
      dispatch(networkData(response?.data?.networks));
    } catch (error) {
      if (error.response?.status == 401) {
        localStorage.clear();
        history.push("/");
      }
    }
  };

  useEffect(() => {
    getNetowrkData();
    getInfo();
  }, [currencySelector?.walletUpdateBalance]);

  const handleLogout = () => {
    dispatch(userCleanUp());
    dispatch(KYCCleanUp());
    dispatch(currencyLogoutCleanUp());
    dispatch(swapLogoutCleanUp());
    dispatch(subscriptionCleanUp());
    dispatch(remittanceCleanUp());
    localStorage.clear();
    history.push("/");
  };
  //-----------Get update balance from wallet------------
  useEffect(() => {
    getUserInfo();
    dispatch(getCurrencyDetail());
    dispatch(getSetting());
    dispatch(UpdateWalletBalance());
    subscribeData();
  }, []);
  useEffect(() => {
    if (block) {
      alert.show("Your Account has been Blocked Please login again", {
        type: "error",
      });
      handleLogout();
    }
  }, [block]);

  //---------subscribe-------//

  const subscribeData = async () => {
    const res = await HTTP_CLIENT.get("subscription/user-subscriptions");
    dispatch(subscriptionStatus(res?.data));
  };

  //-----------Handle error case -----------//

  useEffect(() => {
    if (currencySelector.error) {
      alert.show(currencySelector.error, { type: "error" });
      dispatch(currencyCleanUp());
    }
  }, [currencySelector.error]);

  //-----------detail data--------------//

  const detailData = (data) => {
    dispatch(graphData(data));

    history.push({
      pathname: "/currency-graph",
      state: { detail: data },
    });
  };

  const toggleBalance = () => {
    setHideBalance(!isHideBalance);
  };

  //-----------hide balance from table and graph--------------//

  const hideBalance = (balance) => {
    balance = String(balance);
    const [bal] = balance?.split(" ");
    return balance == 0 && isHideBalance
      ? "*"
      : isHideBalance
      ? `${new Array(bal.length).join("*")}`
      : balance;
  };

  //------------Refresh for update data purpose----------//

  const handleRefresh = () => {
    setActive(true);
    dispatch(getCurrencyDetail());
    dispatch(getSetting());
    dispatch(UpdateWalletBalance());
    dispatch(AseetsForWalletActivation());
    getUserInfo();
    subscribeData();
    setTimeout(() => {
      setActive(false);
    }, 1000);
  };

  //

  const getUserInfo = async () => {
    try {
      let resr = await HTTP_CLIENT.get(`${BASE_URL}user/info`);
      if (resr) {
        dispatch(userInfo(resr.data.user));
      }
    } catch (error) {
      // alert.show(error?.response?.data?.message, { type: "error" });
    }
  };

  // chat subscription//
  const socket = useContext(SocketContext);
  const userInfoReducer = useSelector(
    (state) => state?.currency?.currencyInfo?.user
  );
  useEffect(() => {
    socket.on("connect", () => {
      socket.emit(CHAT_SOCKET_TYPES.USER_CONNECT, {
        userId: userInfoReducer?._id,
      });
      socket.emit("room", userInfoReducer?._id);
    });

    socket.on("connect_error", (err) => {});
    return () => {
      socket.removeAllListeners();
    };
  }, []);

  //------IsWallet Socket------//

  useEffect(() => {
    socket.on(userEmail, (data) => {
      if (
        data?.type == "walletActivated" &&
        data?.user?.isWalletActivated == true
      ) {
        alert.show("Your vault is activated", {
          type: "success",
        });

        setIsActivate(data?.user?.isWalletActivated);

        dispatch(userInfo(data?.user));
        dispatch(getCurrencyDetail());
        return;
      }
    });
  }, [window.location.href]);

  //------Wallet Updated Socket------//

  useEffect(() => {
    socket.on(userEmail, (data) => {
      if (data.type == "nativeWalletUpdate") {
        dispatch(getCurrencyDetail());
        return;
      }
    });
  }, []);

  //----Withdrawal Function-----//

  const detailDataa = (obj) => {
    let data = { ...obj, activeButton };
    history.push({
      pathname: "/vault-graph",
      state: { detail: data },
    });
  };

  const liquidityDetails = (data) => {
    history.push({
      pathname: "/liquidity-graph",
      state: { detail: data },
    });
  };
  const oppertunityDetails = (data) => {
    if (currencySelector.currencyInfo.user.isWalletActivated) {
      history.push({
        pathname: "/silent-wave",
        state: { detail: data },
      });
    } else {
      history.push("/error");
    }
  };
  const handleWithdrawal = () => {
    if (currencySelector.currencyInfo.user.isWalletActivated) {
      history.push("/withdrawal");
    } else {
      history.push("/error");
    }
  };

  //---------------Redirect to profile if kyc not filled-------------//

  const userSelector = useSelector((state) => state.currency);
  const userKyc = useSelector((state) => state?.user);

  useEffect(() => {
    if (
      !userKyc?.savedFormData?._id &&
      !userKyc?.kyc?._id &&
      !userSelector?.currencyInfo?.gpaData?._id
    ) {
      history.push("/profile");
      alert.show("Please fill your GPA data first", {
        type: "error",
      });
    }
  }, []);

  useEffect(() => {
    const handleBeforeUnload = () => {
      const newLocation = { ...location, state: { activeState: null } };
      // Use history.replace to update the location without adding a new entry to the history stack
      history.replace(newLocation);
    };

    window.addEventListener("beforeunload", handleBeforeUnload);

    return () => {
      window.removeEventListener("beforeunload", handleBeforeUnload);
    };
  }, []);

  return (
    <>
      <Modal visible={popupvisible} btn onClose={() => setpopupvisible(false)}>
        <Popup data={number} />
      </Modal>
      {/* <PrivateChat /> */}
      <div className={styles.container}>
        <div className={styles.loaderWrapper}>
          Refresh
          {active ? (
            <img
              onClick={handleRefresh}
              className={styles.active}
              src={refrsh}
              alt=""
            />
          ) : (
            <img
              onClick={handleRefresh}
              className={styles.refresh}
              src={refrsh}
              alt=""
            />
          )}
        </div>

        <div className={styles.wrapper}>
          <div className={styles.card}>
            {/* {activeButton === 1 ? (
              <Alert
                AlertShow={isActivate}
                isSidra={isSidra}
                loading={currencySelector.currencyDetailLoader}
                handleClick={(e) => togglepopup(e, 1)}
                Click={(e) => togglepopup(e, 2)}
              /> */}

            <Alert
              AlertShow={isActivate}
              isSidra={isSidra}
              loading={currencySelector.currencyDetailLoader}
              handleClick={(e) => togglepopup(e, 4)}
              Click={(e) => togglepopup(e, 5)}
            />
          </div>
          {activeButton === 1 ? (
            <DashboardChart
              currency={currencySelector?.currencyInfo?.wallets}
              num={totalBalance}
              toggleBalance={toggleBalance}
              hideBalance={hideBalance}
              isHideBalance={isHideBalance}
            />
          ) : activeButton === 2 ? (
            <DashboardChart
              currency={liquidityDisplay}
              num={liquidityTokens}
              toggleBalance={toggleBalance}
              hideBalance={hideBalance}
              isHideBalance={isHideBalance}
            />
          ) : activeButton === 3 ? (
            <DashboardChart
              currency={arrToDisplay}
              num={totalAsset}
              toggleBalance={toggleBalance}
              hideBalance={hideBalance}
              isHideBalance={isHideBalance}
            />
          ) : activeButton === 4 ? (
            <DashboardChart
              currency={tokenList}
              num={oppertunity}
              toggleBalance={toggleBalance}
              hideBalance={hideBalance}
              isHideBalance={isHideBalance}
            />
          ) : activeButton === 5 ? (
            <DashboardChart
              currency={sdiraTokenDisplay}
              num={totalSdiraTokens}
              toggleBalance={toggleBalance}
              hideBalance={hideBalance}
              isHideBalance={isHideBalance}
            />
          ) : (
            ""
          )}

          <div className={styles.buttonWrapper}>
            <button
              onClick={() => setActiveButton(2)}
              className={activeButton === 2 ? styles.button2 : styles.button1}
            >
              Liquidity Tokens
            </button>
            <button
              onClick={() => setActiveButton(3)}
              className={activeButton === 3 ? styles.button2 : styles.button1}
            >
              Asset Tokens
            </button>
            <button
              onClick={() => setActiveButton(4)}
              className={activeButton === 4 ? styles.button2 : styles.button1}
            >
              Opportunity Tokens
            </button>
            <button
              onClick={() => setActiveButton(5)}
              className={activeButton === 5 ? styles.button2 : styles.button1}
            >
              Legacy Tokens
            </button>
            <button
              className={activeButton === 1 ? styles.button2 : styles.button1}
              onClick={() => setActiveButton(1)}
              // clickHandler={() => handleWithdrawal()}
            >
              Cryptocurrency
            </button>
          </div>
          <div className={styles.tableContainer}>
            <div className={styles.tableWrapper}>
              {activeButton === 1 ? (
                <Table
                  recieveData={detailData}
                  currencyData={currencySelector?.currencyInfo?.wallets}
                  load={
                    currencySelector.loading && !currencySelector?.currencyInfo
                  }
                  isHideBalance={isHideBalance}
                  hideBalance={hideBalance}
                  dataFile={1}
                />
              ) : activeButton === 3 ? (
                <Table
                  recieveData={detailDataa}
                  CQRData={assetToken} // currencyData={currencySelector?.currencyInfo?.wallets}
                  load={
                    currencySelector.loading && !currencySelector?.currencyInfo
                  }
                  isHideBalance={isHideBalance}
                  hideBalance={hideBalance}
                  dataFile={2}
                />
              ) : activeButton === 2 ? (
                <Table
                  CQRData={liquidityToken}
                  recieveData={liquidityDetails}
                  // currencyData={currencySelector?.currencyInfo?.wallets}
                  load={
                    currencySelector.loading && !currencySelector?.currencyInfo
                  }
                  isHideBalance={isHideBalance}
                  hideBalance={hideBalance}
                  dataFile={1}
                />
              ) : activeButton === 4 ? (
                <HistoryTable
                  recieveData={oppertunityDetails}
                  isHideBalance={isHideBalance}
                  hideBalance={hideBalance}
                />
              ) : activeButton === 5 ? (
                <Table
                  activeButton={activeButton}
                  recieveData={detailDataa}
                  CQRData={sdiraTokens} // currencyData={currencySelector?.currencyInfo?.wallets}
                  load={
                    currencySelector.loading && !currencySelector?.currencyInfo
                  }
                  isHideBalance={isHideBalance}
                  hideBalance={hideBalance}
                  dataFile={2}
                />
              ) : (
                ""
              )}
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default CryptoCurrency;
